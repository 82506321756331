


import request from '@/utils/request.js'




export const login = (username, password) => {
  return request({
    url: '/auth/login',
    method: 'POST',
    data: {
      username, password
    },
  })
}


export const logout = () => {
  return request({
    url: '/auth/logout',
    method: 'DELETE',
  })
}
