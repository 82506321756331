




import storage from "@/utils/localStorageUtils";
import { commonObject } from "@/variable/common.js";

export function getToken() {
  return storage.get(commonObject.token)
}

export function setToken(token) {
  return storage.set(commonObject.token, token)
}

export function removeToken() {
  return storage.remove(commonObject.token)
}

