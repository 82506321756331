import { getToken, setToken, removeToken } from "@/utils/auth.js"
import { login, logout } from "@/api/login.js"
import modal from "@/plugins/modal.js"
import router from "@/router/index.js"




const user = {
  state: {
    token: getToken(),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
      setToken(token)
    },
  },
  
  actions: {
    
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim()
      const password = userInfo.password.trim()

      
      return new Promise((resolve, reject) => {
        login(username, password).then(res => {
          let data = res.data
          if (data && data.access_token) {
            commit('SET_TOKEN', data.access_token)
          }
          resolve(res);
        }).catch(error => {
          reject(error)
        })
      });
    },
    
    Logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout().then(res => {
          commit("SET_TOKEN", null)
          
          removeToken()
          
          router.push("/login");
          modal.successMsg("成功退出登录!");
          resolve()
        })
      })
    }
  }
}

export default user
