import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"


import '@/assets/css/gloable.css';

import "@/utils/vant-ui.js"


import plugins from './plugins'
Vue.use(plugins)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
