import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from "@/utils/auth.js"
import store from '@/store'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace


VueRouter.prototype.push = function push(location, onResolve, onReject) {
  return originalPush.call(this, location).catch((err) => err)
}


VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  return originalReplace.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/views/index.vue'),
    redirect: '/home',
    
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { name: "面瘫检测系统" },
        component: () => import('@/views/home/index.vue')
      },
      {
        path: '/personalCenter',
        name: 'personalCenter',
        meta: { name: "个人中心" },
        component: () => import('@/views/personalCenter/index.vue')
      },
      {
        path: '/score/:id',
        props: true,
        name: '检测详情',
        meta: { name: "检测详情" },
        component: () => import('@/components/DetectiomDetils/index.vue')
      },
      {
        path: '/photograph/:taskId',
        props: true,
        name: '录入数据',
        meta: { name: "录入数据" },
        component: () => import('@/views/photograph/index.vue')
      },
      {
        path: '/playVideo',
        props: true,
        name: '面瘫康复',
        meta: { name: "面瘫康复" },
        component: () => import('@/views/PlayVideo/index.vue')
      },
    ]
  },
  {
    path: '/login',
    name: '登录页',
    component: () => import('@/views/Login.vue')
  },

]


let createRouter = () => new VueRouter({
  
  mode: 'hash',
  routes,
  
  scrollBehavior: () => ({ y: 0 })
});


export function resetRouter() {
  router.matcher = createRouter().matcher
}

const router = createRouter()


const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  if (getToken()) {
    
    if (to.path === '/login') {
      next({ path: '/' })
    } else if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      
      
      
      
      
      

      
      

      
      
      
      
      
      
      
      
      
      
      
      

      
      
      
      
      
      next();
    }
  } else {
    
    if (whiteList.indexOf(to.path) !== -1) {
      
      next();
    } else {
      next("/login");
    }
  }
})

export default router
