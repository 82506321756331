





export const commonObject = {
  
  token: 'Admin-Token',
  
  user: 'userInfo',
  
  routers: 'routers',
  
  permissions: 'permissions',
  
  roles: 'roles',
};
