





import { Notify, Dialog } from 'vant';

export default {
  
  primaryMsg(content) {
    Notify({ type: 'primary', message: content });
  },
  
  successMsg(content) {
    Notify({ type: 'success', message: content });
  },
  
  dangerMsg(content) {
    Notify({ type: 'danger', message: content });
  },
  
  warningMsg(content) {
    Notify({ type: 'warning', message: content });
  },
  
  alert(content) {
    Dialog({ message: content });
  },
  
  alertTitile(titile, content) {
    return Dialog.alert({
      title: titile,
      message: content,
    });
  },
  
  alertConfirme(titile, content) {
    return Dialog.confirm({
      title: titile,
      message: content,
    });
  }
}
