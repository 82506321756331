import Vue from "vue"
import { Button, Slider, Popup, List, Form, Field, NavBar, Cell, Calendar, Divider, Icon, Tabbar, TabbarItem, PullRefresh } from "vant";

Vue.use(Button);
Vue.use(Slider);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Calendar);
Vue.use(Divider);
Vue.use(Icon);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(PullRefresh);
Vue.use(List);
